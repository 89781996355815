import styled from 'styled-components';
import {themeGet} from "@styled-system/theme-get";

const FeatureSectionWrapper = styled.section`
  padding: 0px 0 0 0;
  white-space: pre-line;

  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    .block {
      display: flex;
      flex-direction: row;
    }
    img {
      width: 100px;
      height: 100px;
      objectFit: contain;
      @media (max-width: 992px) {
        width: 80px;
        height: 80px;
      }
      @media (max-width: 768px) {
        width: 60px;
        height: 60px;
        
      }
     
    }
    //.polkadot {
    //  @media (max-width: 768px) {
    //    margin-right: 30%;
    //    margin-left: 30%;
    //
    //  }
    //}
  }
  .sectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: white;
    font-size: 60px;
    font-weight: 700;
    font-style: normal;
    color: ${themeGet('colors.menu', '#0D233E')};
    //line-height: 110%;
    @media only screen and (max-width: 1440px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 40px;
      text-align: center;
    }
    @media only screen and (max-width: 350px) {
      font-size: 30px;
      text-align: center;
    }
  }

  h2 {
    color: white;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 18px;
      text-align: center;
    }
  }
  h3 {
    color: white;
    font-size: 18px;

    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 13px;
      text-align: center;
    }
  }
  
`;

export default FeatureSectionWrapper;
