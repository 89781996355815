import styled from 'styled-components';
import {themeGet} from "@styled-system/theme-get";

const FeatureSectionWrapper = styled.section`
  width: 100%;
  padding: 50px;
  @media (max-width: 990px) {
    padding: 80px 0 0 0;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;

  }
  .sectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: white;
    font-size: 60px;
    font-weight: 700;
    font-style: normal;
    color: ${themeGet('colors.menu', '#0D233E')};
    //line-height: 110%;
    @media only screen and (max-width: 1440px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 40px;
      text-align: center;
    }
    @media only screen and (max-width: 350px) {
      font-size: 30px;
      text-align: center;
    }
  }
  
  h2 {
    color: white;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 18px;
      text-align: center;
    }
  } 
  h3 {
    color: white;
    font-size: 18px;

    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1220px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 13px;
      text-align: center;
    }
  }
  .ReactGridGallery_tile-bottom-bar {
    div {
      span {
        color: white !important;
      }
    } 
  }
  .ReactGridGallery_tile-viewport {
    img {
      object-fit: cover !important;
    }
  }
`;




export default FeatureSectionWrapper;
