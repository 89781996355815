import React from 'react';
import PropTypes from 'prop-types';
import "react-image-lightbox/style.css";
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import foto1 from '../../../common/assets/media/about-us1.jpg';
import foto2 from '../../../common/assets/media/about-us2.jpg';
import FeatureSectionWrapper from './aboutUs.style';
import { Decor1 } from '../Banner';
import { Decor3 } from '../TradersSection';
import { Flex } from '../Banner/banner.style';
import CountUp from 'react-countup';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';

const MainTitle = styled.h2`
  font-size: 56px !important;
  font-weight: 800 !important;
  color: #222222 !important;
  margin-bottom: 10px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  text-align: left !important;
  @media (max-width: 992px) {
  margin-top: 100px;
    font-size: 28px !important;
  }
`;

const SubTitle = styled.h2`
  font-size: 24px !important;
  font-weight: 500;
  color: #222222 !important;
  margin-bottom: 20px !important;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  text-align: left;
  @media (max-width: 992px) {
    font-size: 20px !important;
  }
`;

const Description = styled.h3`
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #222222 !important;
  margin-bottom: 20px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  width: 70%;
  text-align: left !important;
  @media (max-width: 992px) {
    font-size: 16px;
    width: 100%;
  }
`;

const ImagesContainer = styled.div`
  flex: 0 0 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    width: 100%;
    flex: 1 1 auto;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 30px;
  }
`;

const Image1 = styled.img`
  width: 60%;
  position: relative;
  z-index: 2;
  transform: translate(100px, 120px);
  @media (max-width: 992px) {
    width: 60%;
    transform: translate(60%, 90px);
    position: relative;
  }
`;

const Image2 = styled.img`
  width: 80%;
  position: absolute;
  z-index: 1;
  @media (max-width: 992px) {
    width: 80%;
    position: absolute;
    margin-top: 30px;
  }
`;

const TextContainer = styled.div`
  flex: 0 0 50%;
  padding: 20px;
  gap: 16px;
  @media (max-width: 992px) {
    width: 100%;
    padding: 0;
  }
`;

const StyledNumberFlex = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  border-left: 2px solid #eeb916;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 992px) {
    padding: 20px;
    box-shadow: none;
    border-left: none;
    border-bottom: 2px solid #eeb916;
    width: 100%;
  }
`;

const NumbersSection = ({number, title}) => {
  return (
  <StyledNumberFlex>
    <Flex style={{gap: '10px', alignItems: 'center'}}>
      <CountUp end={number} duration={3} separator="," style={{fontSize: '56px', fontWeight: 800, color: '#000'}}/>
      <h3 style={{color: '#000', fontSize: '24px', fontWeight: 'bold', margin: '0px'}}>+</h3>
    </Flex>
    
    <Flex style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
      <h3 style={{color: '#000', textAlign: 'left', fontSize: '24px', fontWeight: 'bold', margin: '0px'}}>{title}</h3>
    </Flex>
  </StyledNumberFlex>
)}

const customSection = [
  {
    number: 100000,
    title: 'Ore di lavoro',
  },
  {
    number: 20,
    title: 'Anni di esperienza',
  },
  {
    number: 30,
    title: 'Parco mezzi',
  },
  {
    number: 100,
    title: 'Clienti soddisfatti',
  }
]

const AboutUsSection = ({ sectionHeader }) => {
  const {width} = useWindowDimensions()
  return (
    <Fade up delay={50}>
      <FeatureSectionWrapper id="about-us">
        <Flex style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <ImagesContainer>
            <Image2 src={foto2} alt="About Us 2" />
            <Image1 src={foto1} alt="About Us 1" />
          </ImagesContainer>
          <TextContainer>
            <MainTitle>Da oltre 20 anni al vostro servizio</MainTitle>
            <Flex style={{ gap: '8px', margin: '30px 0px', width: '70px' }}>
              <Decor1 />
              <Decor3 />
              <Decor1 />
            </Flex>
            <SubTitle></SubTitle>
            <Description>
              Operiamo nel settore dei trasporti da oltre 20 anni. La nostra esperienza e professionalità ci permettono di offrire un servizio di qualità e sicuro, affiancando professionisti e privati in ogni loro esigenza.
            </Description>
            <Description>
              Il nostro parco veicoli è composto da mezzi di ultima generazione, in grado di garantire la massima sicurezza e affidabilità. I nostri autisti sono altamente qualificati e in grado di affrontare qualsiasi situazione.
            </Description>
          </TextContainer>
        </Flex>
        <Flex style={{ flexWrap: 'wrap', marginTop: '20px', flexDirection: width >= 992 ? 'row' : 'column', gap: '32px', marginTop: '100px', justifyContent: 'flex-start' }}>
              {customSection.map((item, index) => (
                <NumbersSection 
                  key={index}
                  number={item.number}
                  title={item.title}
                />
              ))}
            </Flex>
      </FeatureSectionWrapper>
    </Fade>
  );
};

// FeatureSection style props
AboutUsSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
AboutUsSection.defaultProps = {
  // section header default style
  sectionHeader: {
    pr: ['0%', '5%', '16%', '5%'],
    pl: ['0%', '5%', '16%', '5%'],
    mb: ['40px', '40px', '40px', '50px'],
    display: 'flex',
    width: '100%',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['28px', '60px'],
    fontWeight: '700',
    lineHeight: '130%',
    color: '#fff',
    mb: '15px',
    w: ['100%', '40%'],
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '24px'],
    fontWeight: '500',
    lineHeight: '156%',
    color: '#E0DAE2',
    mb: '0',
    w: ['100%', '40%'],
  },
};

export default AboutUsSection;
