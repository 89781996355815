import React, { useRef } from 'react';
import Container from '../../../common/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';
import { Flex } from '../../../common/components/UI/Container/style';
import { Decor1 } from '../Banner';


export const ImageContainer = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  height: 100%;
  width: 100%;
  border-radius: 17px;
  background-color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 10%, rgba(23, 23, 23, 0) 90%, rgba(23, 23, 23, 1) 100%),
                linear-gradient(to right, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 10%, rgba(23, 23, 23, 0) 90%, rgba(23, 23, 23, 1) 100%),
                linear-gradient(to top, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 10%, rgba(23, 23, 23, 0) 90%, rgba(23, 23, 23, 1) 100%),
                linear-gradient(to left, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 10%, rgba(23, 23, 23, 0) 90%, rgba(23, 23, 23, 1) 100%);
    border-radius: inherit;
    pointer-events: none;
}
`;

export const CustomHeading = styled.h2`
  font-size: 75px !important;
  font-weight: 900 !important;
  color: #222222;
  margin-bottom: 20px;
  font-family: 'Exo 2', sans-serif !important;
  margin-bottom: 5px;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 32px !important;
  }
`

export const DetailsSection = styled.div`
  display: flex;
  background-color: #1e1e1e;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 17px;
  padding: 0px 20px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  @media (min-width: 850px) {
    width: 29%;
    height: 100%;
  }
`

export const DetailsTitle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0.05em;
  color: #222222;
  margin-bottom: 10px;
  margin-top: 20px;
  z-index: 10;
  font-family: 'Exo 2', sans-serif !important;
`

export const DetailsSubTitle = styled.div`
  font-size: 14px;
  width: 100%;
  font-weight: 700 !important;
  color: #222222;
  margin-bottom: 20px;
  width: 70%;
  text-align: left;
  line-height: 120%;
  font-family: 'Exo 2', sans-serif !important;
  z-index: 10;
`

export const DetailsImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 50px;
  background-color: #eeb916;
  border-radius: 50%;
  margin-right: 20px;
` 

const MainTitle = styled.h2`
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #222222 !important;
  margin-bottom: 20px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 32px;
  }
`

export const Decor3 = styled.div`
  width: 5px;
  height: 5px;
  background-color: #eeb916;
  margin: 0 auto;
`

const ItemContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 17px;
  width: 100%;
  @media (min-width: 850px) {
    width: 20%;
  }
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  width: 300px;
  height: 300px;
  border-radius: 17px;
`

const Item = ({ title, image }) => {
  const categoryToUrl = title.toLowerCase().replace(/ /g, '-');
  return (
    <ItemContainer href={`/veicoli?category=${categoryToUrl}`}>
      <BackgroundImage image={image} />
      <DetailsTitle>{title}</DetailsTitle>
    </ItemContainer>
  );
};

const TradersSection = ({sectionHeader, sectionSubTitle}) => {
    const {width} = useWindowDimensions()
    const ref = useRef(null);

    const mezzi = [
      {
        title: "Furgoni",
        image: "https://cdn.drivek.com/configurator-imgs/trucks/it/1600/IVECO/DAILY/8658_CARGO-VAN-4-DOORS/iveco-daily-cargo-van-2022-front-side-1.jpg"
      },
      {
        title: "Piattaforme aeree",
        image: "https://assets.palfinger.com/cache-buster-1678789394/importdata/product-data/access-platforms/images/p-200-axe-e-ive/image-thumb__95361__lightbox/IMG_4678.webp"
      },
      {
        title: "Ragni",
        image: "https://cmclifts.com.au/wp-content/uploads/2016/05/CMC-S15-Spider-Lift-Main-Image-1-1080x720.jpg"
      },
      {
        title: "Semoventi elettriche",
        image: "https://haulotte.ephoto.fr/link/3c9igq/jdw19y7efaqsiu8.jpeg"
      },
      {
        title: "Muletti elettrici",
        image: "https://media-live2.prod.scw.jungheinrichcloud.com/resource/image/410368/landscape_ratio5x4/970/776/18d677c4c3ced7e9d8f8ccf8d13f8cc7/ED3AA9D5A59D810874103BC2C89BAF53/stage-efg-535k-540k-540-545k-545-550-s40-s50.jpg"
      },
      {
        title: "Verticali elettriche",
        image: "https://fuglesangdahl.no/wp-content/uploads/2023/02/sunward_swsl-1008dc-4.png"
      },
      {
        title: "Verticali diesel",
        image: "https://www.mayonmachinery.com/wp-content/uploads/2021/08/Haulotte-H18SX-1.jpg"
      },
      {
        title: "Sollevatori",
        image: "https://agronotizie.imagelinenetwork.com/sitocommon/thumbs/1360/kFotoProdotto/278732_20220112171445.jpeg"
      }
    ]

    return (

        <FeatureSectionWrapper ref={ref} id="servizi">
            <Container mobileGutter width={width >= 1440 ? '100%' : '100%'} style={{zIndex: 10}}>
              <Flex style={{flexDirection: 'column'}}>
                <MainTitle>
                  Scegli il tuo mezzo
                </MainTitle>
                <Flex style={{gap: '8px', marginBottom: '30px'}}>
                  <Decor1 /><Decor3 /><Decor1 />
                </Flex>
                <Flex style={{gap: '24px', flexWrap: 'wrap'}}>
                  {mezzi.map((item, index) => (
                    <Item title={item.title} image={item.image} key={index} />
                  ))
                  }
                </Flex>
              </Flex>
            </Container>
        </FeatureSectionWrapper>
    );
};

export default TradersSection;
