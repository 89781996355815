import React from 'react';
import PropTypes from 'prop-types';
import "react-image-lightbox/style.css";
import Fade from 'react-reveal/Fade';
import FeatureSectionWrapper from './featureSection.style';
import { Decor1 } from '../Banner';
import { Decor3 } from '../TradersSection';
import { Flex } from '../Banner/banner.style';
import styled from 'styled-components';
import foto from '../../../common/assets/media/test.jpg'
import { mostUsedVehicles } from '../../../common/constants';
import { Details } from '../VeicoliSection';

const MainTitle = styled.h2`
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #222222 !important;
  margin-bottom: 10px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 32px;
  }
`

const SubTitle = styled.h2`
  font-size: 24px !important;
  font-weight: 500;
  color: #222222 !important;
  margin-bottom: 20px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 20px;
  }
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  margin-bottom: 20px;
  background-position: center;
  width: 360px;
  height: 280px;
  border-radius: 17px;
`
// export enum VehicleType {
//   FURGONE = 'Furgone',
//   PIATTAFORMA_AEREA = 'Piattaforma aerea',
//   RAGNO = 'Ragno',
//   SEMOVENTE_ELETTRICA = 'Semovente elettrica',
//   SEMOVENTE_DIESEL = 'Semovente diesel',
//   VERTICALE_ELERTTRICA = 'Verticale elettrica',
//   VERTICALE_DIESEL = 'Verticale diesel',
//   SOLLEVATORI = 'Sollevatori',
// }

export const vehicles = [
  {
    title: "Piaggio Porter",
    image: "https://i.imgur.com/8xwqgDT.jpeg",
    details: [
      {
        title: "Posti",
        description: "2"
      },
      {
        title: "Cilindrata",
        description: "4200cc"
      },
      {
        title: "Potenza",
        description: "100cv"
      },
      {
        title: "Capacità di carico",
        description: "1000kg"
      },
      {
        title: "Velocità massima",
        description: "120km/h"
      },
      {
        title: "Porte",
        description: "3"
      }
    ],
    price: "€80/giorno + IVA"
  },
  {
    title: "Mitsubishi Fuso",
    image: 'https://i.imgur.com/hLJrxLG.jpeg',
    details: [
      {
        title: "Posti",
        description: "4"
      },
      {
        title: "Porte",
        description: "5"
      },
      {
        title: 'Altezza massima',
        description: '30m'
      },
      {
        title: 'Portata massima',
        description: '200kg'
      },
      {
        title: 'Velocità massima',
        description: '120km/h'
      }
    ],
    price: "€120/giorno + IVA"
  },
  {
    title: "Sunward SWE18UF",
    image: "https://i.imgur.com/jqstXRK.jpeg",
    details: [
      {
        title: "Altezza massima",
        description: "15m"
      },
      {
        title: "Portata massima",
        description: "200kg"
      },
      {
        title: "Peso",
        description: "2000kg"
      },
      {
        title: "Alimentazione",
        description: "Elettrica"
      }
    ],
    price: "€80/giorno + IVA"
  },
  {
    title: "Palfinger P200AXE",
    image: "https://i.imgur.com/mvFijU7.jpeg",
    details: [
      {
        title: "Posti",
        description: "4"
      },
      {
        title: "Portata massima",
        description: "200kg"
      },
      {
        title: "Altezza massima",
        description: "20m"
      },
      {
        title: "Alimentazione",
        description: "Diesel"
      },
      { title: "Velocità massima", description: "120km/h"}
    ],
    price: "€130/giorno + IVA"
  }
]

const FeatureSection = ({
  sectionHeader,
}) => {

  return (
    <Fade up delay={50}>
      <FeatureSectionWrapper id="highlights">
        <Flex style={{ flexDirection: "column" }}>
          <MainTitle>I più noleggiati</MainTitle>
          <SubTitle>Scopri i nostri veicoli più richiesti</SubTitle>
          <Flex style={{ gap: "8px", marginBottom: "30px" }}>
            <Decor1 />
            <Decor3 />
            <Decor1 />
          </Flex>
          <Flex style={{ gap: "24px", flexWrap: "wrap" }}>
            {mostUsedVehicles.map((item, index) => (
              <Details title={item.title} images={item.images} details={item.details} price={item.price} key={index} category={item.category} />
            ))}
          </Flex>
        </Flex>
      </FeatureSectionWrapper>
    </Fade>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    pr: ['0%', "5%", "16%", "5%"],
    pl: ['0%', "5%", "16%", "5%"],
    mb: ['40px', '40px', '40px', '50px'],
    display: 'flex',
    width: '100%',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['28px', '60px'],
    fontWeight: '700',
    lineHeight: '130%',
    color: '#fff',
    mb: '15px',
    w: ['100%', '40%'],

  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '24px'],
    fontWeight: '500',
    lineHeight: '156%',
    color: '#E0DAE2',
    mb: '0',
    w: ['100%', '40%'],
  },
};

export default FeatureSection;
