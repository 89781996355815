import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "react-image-lightbox/style.css";
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import FeatureSectionWrapper from './aboutUs.style';
import { Flex } from '../Banner/banner.style';
import { Decor1 } from '../Banner';
import { Decor3 } from '../TradersSection';
import uno from '../../../common/assets/media/uno.jpg'
import due from '../../../common/assets/media/due.jpg'
import tre from '../../../common/assets/media/tre.jpg'

// Sample reviews data with placeholder images for testing
const reviews = [
  {
    id: 1,
    name: 'Luca Verdi',
    title: 'Impiegato edile',
    image: uno,
    review: 'Sono rimasto molto soddisfatto del servizio offerto. Il team è stato professionale e attento a tutte le nostre esigenze. La loro esperienza ci ha aiutato a innovare e a ottenere risultati eccezionali per i nostri clienti.',
  },
  {
    id: 2,
    name: 'Giovanni Bianchi',
    title: 'Fondatore impresa di pulizia',
    image: due,
    review: 'Il team è stato molto professionale e disponibile. Ci ha aiutato a trovare il veicolo più adatto alle nostre esigenze e a migliorare la nostra attività. Consigliatissimo!',
  },
  {
    id: 3,
    name: 'Marco Rossi',
    title: 'Imprenditore',
    image: tre,
    review: 'Noleggio sempre i veicoli da loro e mi sono sempre trovato benissimo. Il personale è molto gentile e disponibile. Consigliatissimo!',
  },
];

// Styled Components
const MainTitle = styled.h2`
  font-size: 36px;
  font-weight: 800;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Barlow', sans-serif;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const SlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const SlideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const ReviewCard = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${({ animateOut }) => (animateOut ? SlideOut : SlideIn)} 0.5s ease-in-out;
`;

const ReviewText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
`;

const QuoteIcon = styled.div`
  font-size: 90px;
  color: #f0a500;
  margin-bottom: -10px;
`;

const ReviewImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
`;

const ReviewName = styled.h4`
  margin: 10px 0 5px;
  font-weight: bold;
  font-size: 18px;
  color: #222;
`;

const ReviewTitle = styled.h5`
  font-size: 14px;
  color: #999;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  &:hover {
    color: #222;
  }
    @media (max-width: 992px) {
    font-size: 14px;
    top: 70%;
  }
`;

const PrevButton = styled(NavButton)`
  left: 30px;
`;

const NextButton = styled(NavButton)`
  right: 30px;
`;

const ReviewSection = ({ sectionHeader }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateOut, setAnimateOut] = useState(false);

  const handleNext = () => {
    setAnimateOut(true);
    setTimeout(() => {
      setAnimateOut(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 500); // Match this with animation duration
  };

  const handlePrev = () => {
    setAnimateOut(true);
    setTimeout(() => {
      setAnimateOut(false);
      setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    }, 500); // Match this with animation duration
  };

  return (
    <FeatureSectionWrapper id="reviews">
      <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Fade up delay={50}>
          <MainTitle style={{color: '#222', fontSize: '56px', fontWeight: 800, fontFamily: 'Barlow, sans-serif', marginBottom: '20px'}}>Cosa dicono i nostri clienti</MainTitle>
        </Fade>
        <Flex style={{ gap: '8px', margin: '30px 0px', width: '70px' }}>
          <Decor1 />
          <Decor3 />
          <Decor1 />
        </Flex>

        <ReviewContainer>
          <ReviewCard animateOut={animateOut}>
            <QuoteIcon>&#8220;</QuoteIcon>
            <ReviewText>{reviews[currentIndex].review}</ReviewText>
            <ReviewImage src={reviews[currentIndex].image} alt={reviews[currentIndex].name} />
            <ReviewName>{reviews[currentIndex].name}</ReviewName>
            <ReviewTitle>{reviews[currentIndex].title}</ReviewTitle>
          </ReviewCard>
          <PrevButton onClick={handlePrev}>&#10094; Prossima</PrevButton>
          <NextButton onClick={handleNext}>Precedente &#10095;</NextButton>
        </ReviewContainer>
      </Flex>
    </FeatureSectionWrapper>
  );
};

ReviewSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

export default ReviewSection;
