import React, { useMemo } from 'react';
import Container from '../../../common/components/UI/Container';
import styled from 'styled-components';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';
import { graphql, useStaticQuery } from 'gatsby';
import { Flex } from '../Banner/banner.style';
import sfondo from '../../../common/assets/media/sfondo.jpg';
import CountUp from "react-countup";
import { Decor1, Decor2 } from '../Banner';
import { Decor3 } from '../TradersSection';

const FlexWithFixedBg = styled(Flex)`
  background-image: url(${sfondo});
  margin-top: 150px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;
  padding: 20px;

  height: auto;
  flex-direction: column;
  @media (min-width: 968px) {
    flex-direction: row;
    padding: 100px;
  }
`;

const StyledSmallTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: rgb(238, 185, 22);
  margin-bottom: 0px;
  text-align: center;
  font-family: 'Barlow', sans-serif;
  letter-spacing: 1px;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 20px;
  }
`;

export const MainTitle = styled.h2`
  font-size: 56px !important;
  font-weight: 900 !important;
  color: #fff !important;
  width: 60%;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  font-family: 'Barlow', sans-serif !important;
  z-index: 10;
  @media (max-width: 992px) {
    font-size: 38px;
    width: 100%;
  }
`

const StyledCard = styled.div`
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  gap: 16px;
  width: 80%;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const Forklift = () => (
  <svg height="40px" width="40px" version="1.1" id="_x32_"   
	 viewBox="0 0 512 512" style={{fill: '#eeb916'}}>
<g>
	<path class="st0" d="M75.335,334.018c-26.14,0-47.356,21.185-47.356,47.356c0,26.132,21.217,47.316,47.356,47.316
		c26.132,0,47.316-21.185,47.316-47.316C122.651,355.202,101.466,334.018,75.335,334.018z M75.335,397.99
		c-9.17,0-16.656-7.445-16.656-16.616c0-9.17,7.485-16.656,16.656-16.656c9.162,0,16.615,7.485,16.615,16.656
		C91.95,390.545,84.497,397.99,75.335,397.99z"/>
	<path class="st0" d="M277.642,122.676c-6.697-23.305-28.018-39.366-52.271-39.366h-142.8c-11.548,0-20.912,9.364-20.912,20.912
		v133.043H46.617C20.871,237.265,0,258.136,0,283.882v81.295h8.608c7.308-30.099,34.412-52.537,66.727-52.537
		c32.299,0,59.378,22.438,66.686,52.537h31.472c2.554-41.864,37.31-75.151,79.8-75.151c23.16,0,43.992,9.958,58.615,25.754v-4.055
		V266.44c0-16.254-2.28-32.435-6.769-48.055L277.642,122.676z M241.103,199.119l-21.827,13.162l33.046,62.912h-59.08l-27.417-37.928
		h-55.508V117.849h115.054c8.802,0,16.647,5.91,19.081,14.367l27.497,95.701c1.871,6.505,3.236,13.13,4.136,19.828L241.103,199.119z
		"/>
	<path class="st0" d="M253.293,311.403c-32.388,0-58.672,26.244-58.672,58.672c0,32.371,26.284,58.615,58.672,58.615
		c32.371,0,58.615-26.244,58.615-58.615C311.908,337.647,285.664,311.403,253.293,311.403z M253.293,390.658
		c-11.363,0-20.639-9.219-20.639-20.582c0-11.364,9.276-20.639,20.639-20.639c11.348,0,20.582,9.275,20.582,20.639
		C273.876,381.438,264.641,390.658,253.293,390.658z"/>
	<path class="st0" d="M389.91,388.2c-16.214,0-29.368-13.154-29.368-29.368V83.31h-38.475v333.038H512V388.2H389.91z"/>
	<path class="st0" d="M230.775,173.911L199.858,195.2c-3.108,2.136-3.887,6.384-1.751,9.492c2.136,3.1,6.384,3.887,9.492,1.751
		l30.918-21.297c3.108-2.136,3.887-6.385,1.751-9.484C238.132,172.554,233.876,171.766,230.775,173.911z"/>
</g>
</svg>
)

const Helmet = () => (
  <svg width="50px" height="50px" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M611.5 484.005V314.527c0-12.22-9.908-22.125-22.125-22.125-12.22 0-22.125 9.905-22.125 22.125v169.477c0 12.217 9.905 22.125 22.125 22.125 12.217 0.001 22.125-9.907 22.125-22.124M478.75 484.005V314.527c0-12.22-9.908-22.125-22.125-22.125-12.22 0-22.125 9.905-22.125 22.125v169.477c0 12.217 9.905 22.125 22.125 22.125 12.217 0.001 22.125-9.907 22.125-22.124M235.375 638.88h575.25c12.217 0 22.125-9.908 22.125-22.125 0-12.22-9.908-22.125-22.125-22.125h-575.25c-12.22 0-22.125 9.905-22.125 22.125 0 12.217 9.905 22.125 22.125 22.125" fill="#eeb916" /><path d="M832.75 598.727v-4.1c-0.047-140.492-93.637-259.161-222.224-297.103-4.688-32.112-31.995-56.819-65.401-56.897h-44.25c-33.409 0.077-60.715 24.784-65.404 56.897-128.586 37.943-222.177 156.612-222.221 297.104v4.1c-25.698 9.166-44.186 33.424-44.25 62.275 0.082 36.654 29.718 66.293 66.375 66.375h575.25c36.654-0.082 66.291-29.721 66.373-66.375-0.065-28.851-18.552-53.109-44.248-62.276z m-22.125 84.401h-575.25c-12.025-0.022-22.103-10.1-22.127-22.125 0.024-12.025 10.102-22.103 22.127-22.125a22.267 22.267 0 0 0 15.642-6.48c4.12-4.12 6.483-9.819 6.483-15.645v-22.125c-0.048-125.391 87.066-230.808 204.227-258.577 9.945-2.359 17.021-11.308 17.021-21.528v-7.52c0.024-12.025 10.102-22.103 22.127-22.125h44.25c12.023 0.022 22.101 10.1 22.125 22.125v7.52c0 10.222 7.073 19.169 17.021 21.528C701.431 363.82 788.546 469.234 788.5 594.628v22.125a22.276 22.276 0 0 0 6.48 15.645 22.268 22.268 0 0 0 15.645 6.48c12.023 0.022 22.101 10.1 22.125 22.125-0.025 12.025-10.103 22.103-22.125 22.125z" fill="#eeb916" /></svg>
)

const Pricing = () => (
  <svg fill="#eeb916" width="40px" height="40px" viewBox="0 0 24 24" ><path d="M2,11.308a1,1,0,0,0,.293.707l9.692,9.692a1,1,0,0,0,1.414,0L21.707,13.4a1,1,0,0,0,0-1.414L12.015,2.293A1,1,0,0,0,11.308,2H3A1,1,0,0,0,2,3ZM4,4h6.894l8.692,8.692-6.894,6.894L4,10.894ZM9.923,7.154a1.958,1.958,0,1,1-2.769,0A1.957,1.957,0,0,1,9.923,7.154Z"/></svg>
)

const motivi = [
  {
    title: 'Veicoli di qualità',
    description: 'Noleggiamo solo veicoli di qualità che vengono regolarmente controllati e manutenuti, garantendo la sicurezza e la soddisfazione del cliente.',
    icon: <Forklift />
  },
  {
    title: 'Assistenza 24/7',
    description: 'Il nostro team è sempre disponibile per assistervi in caso di emergenza o per rispondere a qualsiasi domanda o richiesta.',
    icon: <Helmet />
  },
  {
    title: 'Tariffe competitive',
    description: 'Offriamo tariffe competitive e trasparenti, senza costi nascosti o sorprese sgradevoli.',
    icon: <Pricing />
  }
]




const SmartSection = () => {
    const data = useStaticQuery(graphql`
      query {
        cryptoModernJson {
            Smart {
              text
              subtext
              description
          }
        }
      }
    `);
    const { Smart } = data.cryptoModernJson;
    const {width} = useWindowDimensions()


  return (

      <Flex style={{flexDirection: 'column'}}>
        <Container className={'noPadding'} noGutter width={'calc(100% + 30px)'} fullWidth style={{zIndex: 10, padding: '0px'}}>
          <FlexWithFixedBg>
            {/* {customSection.map((item, index) => (
              <NumbersSection key={index} {...item} />
            ))} */}
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <StyledSmallTitle>
              MOTIVI PER SCEGLIERCI
            </StyledSmallTitle>
            <MainTitle>
              LEADER NEL SETTORE DEL NOLEGGIO DI MEZZI
            </MainTitle>
            <Flex style={{ gap: "8px", margin: '20px auto', justifyContent: 'center', width: '80px' }}>
              <Decor1 />
              <Decor3 />
              <Decor1 />
            </Flex>
            <StyledCardContainer>
              {motivi.map((item, index) => (
                <StyledCard>
                  <div style={{width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', backgroundColor: 'rgba(255,255,255,0.1)'}}>
                    {item.icon}
                  </div>
                  <h3 style={{color: '#000', textAlign: 'center', fontSize: '24px', fontWeight: 'bold', margin: '20px 0px'}}>{item.title}</h3>
                  <p style={{color: 'rgba(0,0,0,0.5)', fontSize: '18px', textAlign: 'center'}}>{item.description}</p>
                </StyledCard>
              ))}
            </StyledCardContainer>
            </div>
            
          </FlexWithFixedBg>
        </Container>
      </Flex>
  );
};

export default SmartSection;
